.app-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 50px;
  padding-left: 100px;

  @media (max-width: 600px) {
    display: none;
  }

  &__item {
    padding-left: 50px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    transition: 0.5s all;
    cursor: pointer;
    flex: 1;
    text-align: center;

    &:hover {
      color: rgba(255, 255, 255, 0.7)
    }
  }
}
