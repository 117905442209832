.trollbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;

  &__messages {
    width: 100%;
    height: calc(100% - 40px);
  }

  &__line {
    padding: 2px;

    span:first-child {
      font-weight: bold;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
  }
}
