.app-terminal {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__log {
    padding: 10px;
    flex: 1;
  }

  &__line {
    padding: 2px;
  }

  &__input {
    height: 40px;
  }
}
