.pagination {
  width: 100%;
  display: flex;
  list-style-type: none;
  justify-content: center;

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
  }

  .next, .previous {
    font-weight: bold;
    cursor: pointer;
  }

  .next {
    margin-left: 5px;
  }

  .previous {
    margin-right: 5px;
  }

  &__page {
    padding: 0px 5px;
    cursor: pointer;

    &--active {
      font-weight: bold;
      color: #000;
    }
  }
}
