.login-page {
  max-width: 600px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 auto;

  &__container {
    min-width: 320px;
  }

  &__title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__form {
    width: 100%;
    margin-top: 20px;

    input {
      margin-top: 5px;

      &::placeholder {
        color: white;
      }
    }

    button {
      margin-top: 10px;
    }
  }
}
