.terminal-order {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-size: 12px;

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  &__buttons {
    margin-top: 10px;
    display: flex;
  }

  @mixin button {
    width: 50%;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    color: white;
    border: none;
  }

  &__buy {
    @include button;
    background: rgba(0, 255, 0, 0.35);
  }

  &__sell {
    @include button;
    background: rgba(255, 0, 0, 0.35);
  }

  &__loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__options {
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    display: flex;
    justify-content: space-around;

    &__item {
      margin-right: 10px;
    }
  }

  &__info {
    margin-top: 10px;
    margin-bottom: 10px;

    &__item {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 50%;

        &:nth-child(2n) {
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }

}
