.terminal-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    text-align: left;
  }

  td, th {
    padding: 2px;
  }

  &__action-cell {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }
  }

  &__status {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #999;

    &--green {
      background: rgba(0, 255, 0, 0.5);
    }

    &--red {
      background: rgba(255, 0, 0, 0.5);
    }
  }
}
