.form {

  &__select {
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__input {
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 14px;
    color: #fff;
    text-indent: 5px;
  }

  &__submit {
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.15);
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &__group {
    padding-bottom: 5px;
  }

  &__error-box {
    color: #b92e2e;
    background: #ffd4d4;
    padding: 5px;
    font-size: 14px;
  }

  &__notice-box {
    color: white;
    background: #004088;
    padding: 5px;
    font-size: 14px;
  }

  &__success-box {
    color: white;
    background: #57bb5b;
    padding: 5px;
    font-size: 14px;
  }

  &__error-msg {
    color: #d40000;
    font-size: 14px;
    margin-top: 3px;
  }

}
