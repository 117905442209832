.table {
  margin-top: 1px;
  padding: 2px;
  background: rgba(0, 0, 0, 0.25);
  max-height: 200px;
  overflow-y: auto;

  &__title {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    text-align: center;

    &__subtitle {
      font-size: 10px;
      font-weight: normal;
      margin-left: 3px;
      position: absolute;
    }
  }

  &__two-columns {
    width: 100%;
    display: flex;

    &__item {
      width: 50%;
    }
  }

  &__header {
    display: flex;
    font-size: 12px;
    padding: 0px 5px;

    &__side { width: 10% }
    &__time { width: 30% }
    &__price { width: 30% }
    &__amount { width: 30% }
  }

  &__row {
    display: flex;
    font-size: 12px;
    margin-top: 1px;
    padding: 0px 5px;
    flex: 1;

    &--bid {
      background: rgba(0, 255, 0, 0.25);

      i {
        font-size: 14px;
        color: rgba(0, 255, 0, 0.75);
      }
    }

    &--ask {
      background: rgba(255, 0, 0, 0.25);

      i {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 0, 0, 0.75);
      }
    }
  }
}
