.books {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;

  &__row {
    width: calc(25% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    @media (max-width: 900px) {
      width: calc(50% - 10px);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-right: 0;
    }
  }
}
