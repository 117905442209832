.stream-commands {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;

  &__button {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    transition: .3s ease-in-out;
    cursor: pointer;
    margin-left: 5px;

    &:hover, &:focus {
      background: rgba(0, 0, 0, 0.50);
    }

    i {
      font-size: 16px;
      margin-right: 6px;
    }

    &--red {
      color: #7d0000;
    }

    &--green {
      color: #007d11;
    }
  }
}
