.order-book {
  background: rgba(0, 0, 0, 0.25);
  padding: 5px;
  text-align: center;
  max-height: 100%;
  width: 100%;

  &__title {
    width: 100%;
    font-weight: bold;
    position: relative;

    &__subtitle {
      font-size: 12px;
      font-weight: normal;
      margin-left: 3px;
      position: absolute;
    }
  }

  &__bid-asks {
    display: flex;
    flex-direction: column;
  }

  &__bids {
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    text-align: left;
    font-size: 13px;
    position: relative;
    color: #66ff66;
  }

  &__asks {
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    font-size: 13px;
    text-align: left;
    position: relative;
    color: #ff6666;
  }

  &__open-bid {
    position: absolute;
    left: 0px;
    padding-left: 5px;
    font-size: 13px;
    margin-top: 5px;
    border-radius: 50%;
    background: rgba(0, 255, 0, 0.75);
    width: 5px !important;
    height: 5px !important;
  }

  &__open-ask {
    position: absolute;
    left: 0px;
    margin-top: 5px;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.75);
    width: 5px !important;
    height: 5px !important;
  }

  &__header {
    display: flex;
    margin-bottom: 5px;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    font-size: 13px;
    text-align: left;
    font-weight: bold;

    > div {
      width: 50%;
    }
  }

  &__level {
    display: flex;
    white-space: nowrap;

    > div {
      width: 50%;
    }
  }

  &__stats {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  &__stats-row {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-bottom: 3px;
    width: 50%;

    &--half {
      width: 50%;
    }

    &--full {
      width: 100%;
    }

    &__label {
      font-weight: bold;
      width: 100%;
    }

    &__value {
      width: 100%;
    }
  }
}
