.stream-content {
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 5px;
  flex: 1;
  overflow: hidden;

  &__table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__thead {
    width: 100%;
    padding-bottom: 5px;
    text-align: left;
    display: flex;
    font-weight: 600;
    padding: 0px 5px;
    min-width: 700px;

    span {
      flex: 1;
    }
  }

  &__tbody {
    flex: 1;
    overflow: auto;
    min-width: 700px;
  }

  &__row {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 2px 5px;
    display: flex;

    @media (max-width: 660px) {
      font-size: 12px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }

    &--bid {
      background: rgba(0, 255, 0, 0.15);
    }

    &--ask {
      background: rgba(255, 0, 0, 0.15);
    }

    span {
      flex: 1;
    }
  }
}
