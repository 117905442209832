.bots {
  width: 100%;
  display: flex;
  flex-direction: row;

  &__list {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 20%;
  }

  &__list-item {
    width: 100%;
    background: rgba(0, 0, 0, 0.25);
    padding: 10px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;

    > div:first-child {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__content {
    width: 80%;
  }

  &__content-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }

  &__book {
    width: 50%;
  }

  &__trades {
    width: 20%;
    flex: 1;
    padding-left: 1px;
  }

  &__ref-markets {
    width: 30%;
    flex: 1;
    padding-left: 1px;
  }

  &__text-content {
    background: rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
}
