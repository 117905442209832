.app-nav {
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  &__logo {
  }

  &__menu {
    flex: 1;
  }
}
