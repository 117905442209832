.terminal-history {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-size: 14px;
  overflow: auto;

  &__title {
    font-weight: bold;
  }

  &__content {
    margin-top: 10px;
  }

  &__link {
    text-decoration: none;
    cursor: pointer;

    &--red {
      color: rgba(255, 0, 0, 0.75);
    }
  }

  &__bottom-right {
    width: 100%;
    padding-top: 5px;
    padding-right: 15px;
    text-align: right;
    font-size: 12px;
  }
}
