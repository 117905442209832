.terminal-leverage {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-size: 12px;
  margin-bottom: 10px;

  &__header {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    svg {
      font-size: 18px;
      margin-right: 10px;
      margin-bottom: -5px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  &__content {
    margin-top: 10px;
  }

  &__buttons {
    margin-top: 10px;
    display: flex;
  }

  @mixin button {
    width: 100%;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    color: white;
    border: none;
  }

  &__submit {
    @include button;
    background: rgba(255, 255, 255, 0.1);

    &--enabled {
      background: rgba(61, 78, 173, 0.5);
    }
  }

  &__loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
