.stream-filter {
  width: 100%;
  margin-top: 10px;

  &__input-container {
    width: 100%;
    display: flex;
  }

  &__input {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    flex: 1;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    transition: .3s ease-in-out;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 2px;
    padding: 0 10px;
    outline: none;

    &:hover, &:focus {
      color: white;
      background: rgba(0, 0, 0, 0.50);
    }
  }

  &__enter {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 500;
    width: 120px;
    height: 60px;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
    cursor: pointer;
    transition: .3s ease-in-out;

    &:hover {
      color: white;
      background: rgba(0, 0, 0, 0.50);
    }
  }

  &__hint {
    width: 100%;
    margin-top: 5px;
    margin-left: 3px;
    font-size: 14px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
