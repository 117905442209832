.logs {
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 5px;
  flex: 1;
  overflow: auto;
  min-height: 400px;

  &__row {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 2px 5px;
    display: flex;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    div {
      margin-right: 5px;
      flex: 1;

      &:first-child {
        flex: 0;
        min-width: 90px;
        font-weight: bold;
      }
    }
  }
}
