@import "components/app-container";
@import "components/app-nav";
@import "components/app-menu";
@import "components/app-terminal";
@import "components/text-input";
@import "components/trollbox";
@import "components/logs";
@import "components/bots";
@import "components/table";
@import "components/data";
@import "components/stream";
@import "components/stream-filter";
@import "components/stream-commands";
@import "components/stream-stats";
@import "components/stream-content";
@import "components/login-page";
@import "components/about-page";
@import "components/books";
@import "components/order-book";
@import "components/trades";
@import "components/terminal";
@import "components/terminal-history";
@import "components/terminal-order";
@import "components/terminal-leverage";
@import "components/terminal-table";
@import "components/terminal-accounts";
@import "components/form";
@import "components/pagination";

$color: #fff;

html, body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  color: $color;
  font-family: nunito sans,helvetica neue,Helvetica,Arial,sans-serif;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  background-color: #14161f;
}

a {
  color: $color;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-family: rajdhani,helvetica neue,Helvetica,Arial,sans-serif;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  transition: .3s ease all;
  border-color: transparent;
  background-color: rgba(255,255,255,.1);
  z-index: 40;
}
