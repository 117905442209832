.terminal-accounts {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;

  a {
    text-decoration: underline;
    color: #fff;
  }

  &__title {
    font-weight: bold;
    text-align: center;
  }

  &__account {
    padding: 5px;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 5px;
    display: flex;
  }

  &__account-info {
    width: 70%;
    text-align: left;
    font-size: 12px;
  }

  &__account-select {
    width: 30%;
  }

  &__button {
    width: 100%;
    height: 100%;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }

    &--selected {
      cursor: default;
      background: rgba(255, 255, 255, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
