$small-screen-breakpoint: 600px;

.terminal {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $small-screen-breakpoint) {
    padding: 5px;
  }

  &__chart {
    width: 70%;
    height: 510px;
    padding-right: 10px;

    @media (max-width: $small-screen-breakpoint) {
      width: 100%;
      padding-right: 0px;
    }
  }

  &__book {
    width: 30%;
    padding-left: 10px;

    @media (max-width: $small-screen-breakpoint) {
      width: 100%;
      padding-left: 0px;
      padding-top: 20px;
    }
  }

  &__account {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__history {
    width: 70%;
    padding-top: 10px;
    padding-right: 10px;

    @media (max-width: $small-screen-breakpoint) {
      width: 100%;
      padding-right: 0px;
      padding-top: 20px;
    }

    > div {
      margin-bottom: 5px;
    }
  }

  &__actions {
    width: 30%;
    padding-top: 10px;
    padding-left: 10px;

    @media (max-width: $small-screen-breakpoint) {
      width: 100%;
      padding-left: 0px;
      padding-top: 20px;
    }

  }
}
