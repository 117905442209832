.stream-stats {
  font-size: 12px;
  position: relative;

  @media (min-width: 500px) {
    height: 0;
  }

  &__container {
    left: 0;
    top: 0;
    margin-left: 5px;
    margin-top: 5px;
    display: inline-block;
  }
}
