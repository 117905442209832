.app-container {
  width: 100%;
  height: 100vh;
  background: url(../../images/stars-bg.svg) 50% 0 no-repeat;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;

  &__title {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    padding: 2px;
    text-transform: uppercase;
  }

  &__header {
    width: 100%;
    padding: 20px;
  }

  &__nav {
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px;
    margin-bottom: 10px;
  }

  &__center {
    margin-top: 10px;
    width: 100%;
    display: flex;
  }

  &__terminal {
    width: 60%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }

  &__logs {
    width: 20%;
    padding: 10px;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.3);
  }

  &__content {
    width: 100%;
  }

  &__trollbox {
    padding: 10px;
    width: 20%;
    background: rgba(0, 0, 0, 0.3);
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
}
