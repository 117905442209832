.data {
  padding: 2px;
  background: rgba(0, 0, 0, 0.25);

  &--fill-height {
    height: 100%;
  }

  &--red {
    color: rgba(255, 0, 0, 0.75);
  }

  &--yellow {
    color: rgba(255, 255, 0, 0.75);
  }

  &--green {
    color: rgba(0, 255, 0, 0.75);
  }

  &__title {
    width: 100%;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__balances {
    display: flex;
    flex-direction: row;
  }

  &__balance {
    flex: 1;
    padding: 5px;
    background: rgba(0, 0, 0, 0.15);
    margin-right: 5px;
    text-align: center;
  }

  &__balance-title {
    font-weight: bold;
    font-size: 10px;
  }

  &__balance-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      position: relative;
      width: 50%;
      padding: 10px;

      span {
        position: absolute;
        font-size: 10px;
        top: 0px;
        margin-left: 5px;
        font-weight: bold;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  &__option {
    flex: 1;
    background: rgba(0, 0, 0, .15);
    padding: 5px 2px;
    font-size: 12px;
    text-align: center;
  }

  &__option-title {
    font-weight: bold;
    margin-right: 5px;
  }

}
